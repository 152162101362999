
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { RelatorioService } from '@/core/services/financeiro';
import { EmpresaService } from '@/core/services/compras/EmpresaService';
import { EmpreendimentoService } from '@/core/services/cadastros';
import { TratarErroApi, createDate, AnoMesDia, formatDate } from '@/assets/scripts/helper';

@Component
export default class PainelVendas extends mixins(Vue, ListPage) {
  overlay = false;
  tab = null;
  empreendimentos = []; 
  empresas = [];

  lotes:any[] = [];
  contratosCanceladosGraphs:any = []
  lotesVendidosGraphs:any = []
  contratosQuitadosGraphs:any = []
 
  NomeDataInicial = "Data Inicial";
  NomeDataFinal = "Data Final";
  dateInicial: string | null = "";
  dateFinal: string | null = "";
  currentData = new Date()
  
  currentYear = new Date().getFullYear()
  currentMonth = new Date().getMonth();
  currentDataInicial = createDate(1, -12, 0)
  firstDayOfNextMonth = new Date(this.currentYear, this.currentMonth + 1, 1);
  lastDayOfCurrentMonth2 = new Date(this.firstDayOfNextMonth.getTime() - 1);
  formattedDateInicial: string | null = formatDate(new Date(this.currentYear, this.currentMonth, 1), 'yyyy-MM-dd');
  formattedDateFinal: string | null = formatDate(this.lastDayOfCurrentMonth2, 'yyyy-MM-dd');
  menuInicial: any = false;
  menuFinal: any = false;
  isProcessing: boolean = false;

  isLoading: boolean = true;
   
  service = new RelatorioService();
  modeloKPIVendasContrato:any = new KPIVendasContratoModel;

  

  formatDate(date, format) {
    if (!date) return '';
      const [year, month, day] = date.split('-');
      if (format == 'yyyy-MM-dd') {
        return `${day}/${month}/${year}`;
      }
    return '';
  }

  breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi'},
    { text: 'Painel de vendas', disabled: true, href: '/financeiro/painel/cobranca'}
  ];
    
  headersLotes: any[] = [
    { text: 'Lotes', align: 'center', value: 'name',},
    { text: 'Tipo', value: "quantidade" },
  ];

  updateLotes() {
    this.lotes = [
      { name: "DISPONÍVEIS", quantidade: this.modeloKPIVendasContrato.estadoLotes.item.quantidadeDisponiveis },
      { name: "INDISPONÍVEIS", quantidade: this.modeloKPIVendasContrato.estadoLotes.item.quantidadeIndisponiveis },
      { name: "RESERVADOS", quantidade: this.modeloKPIVendasContrato.estadoLotes.item.quantidadeReservados },
      { name: "PENHORADOS", quantidade: this.modeloKPIVendasContrato.estadoLotes.item.quantidadePenhorados },
      { name: "INVADIDOS", quantidade: this.modeloKPIVendasContrato.estadoLotes.item.quantidadeInvadidos },
      { name: "VENDIDOS", quantidade: this.modeloKPIVendasContrato.estadoLotes.item.quantidadeVendidos },
    ];
  }

  headersCorretores: any[] = [
    { text: 'Nome', align: 'center', value: 'nome' },
    { text: 'Vendas Realizadas', align: 'center', value: "vendasRealizadas" },
    { text: 'Vendas Canceladas', align: 'center', value: "vendasCanceladas" },
  ]
  

  filter: {
    empresasIds: any;
    empreendimentosIds: any;
    dataInicial: string | null;
    dataFinal: string | null;
  } = {
    empresasIds: 0,
    empreendimentosIds: 0,
    dataInicial: `${AnoMesDia(this.currentDataInicial)}`,
    dataFinal: `${AnoMesDia(this.lastDayOfCurrentMonth2)}`
  };

  get selecionarTodosEmpreendimentos(){
    return this.filter.empreendimentosIds.length === this.empreendimentos.length
  }

  get SelecionarAlgunsEmpreendimentos(){
    return this.filter.empreendimentosIds.length > 0 && !this.selecionarTodosEmpreendimentos
  }

  get iconEmpreendimento(){
    if (this.selecionarTodosEmpreendimentos) return 'mdi-close-box'
    if (this.SelecionarAlgunsEmpreendimentos) return 'mdi-minus-box'
     return 'mdi-checkbox-blank-outline'
  }

  get textEmpreendimento(){
    if (this.selecionarTodosEmpreendimentos) return 'Desmarcar' 
      if (this.SelecionarAlgunsEmpreendimentos) return 'Selecionando...'
      return 'Selecionar todos'
  }

  SelecionarEmpreendimento() {
    this.$nextTick(() => {
      if (this.selecionarTodosEmpreendimentos) {
        this.filter.empreendimentosIds = []
      } else {
        this.filter.empreendimentosIds = this.empreendimentos.slice().map((x:any)=>x.id)
      }
       this.GetKPIVendasContrato();
    })
  } 

  SelecionarEmpresa(){
    this.$nextTick(() => {
      if (this.selecionarTodosEmpresas) {
        this.filter.empresasIds = []
      } else {
        this.filter.empresasIds = this.empresas.slice().map((x:any)=>x.id)
      }
      this.GetKPIVendasContrato()
    })
  }
  
  get iconEmpresa(){
    if (this.selecionarTodosEmpresas) return 'mdi-close-box'
    if (this.SelecionarAlgunsEmpresas) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  get textEmpresa(){
    if (this.selecionarTodosEmpresas) return 'Desmarcar'
    if (this.SelecionarAlgunsEmpresas) return 'Selecionando...'
    return 'Selecionar todos'
  } 

  get selecionarTodosEmpresas(){
    return this.filter.empresasIds.length === this.empresas.length
  }

  get SelecionarAlgunsEmpresas(){
    return this.filter.empresasIds.length > 0 && !this.selecionarTodosEmpresas
  }

  handleDateSelected({ date, type }: { date: string; type: string }) {


    if (type === 'initial') {
        this.filter.dataInicial = date;
    } else if (type === 'final') {
        this.filter.dataFinal = date;
    }
    this.GetKPIVendasContrato();
    }

  async GetKPIVendasContrato(){
    this.isLoading = true
    this.filter.empreendimentosIds = this.filter.empreendimentosIds ? [...this.filter.empreendimentosIds] : [],
    this.filter.empresasIds = this.filter.empresasIds ? [...this.filter.empresasIds] : []

    this.contratosCanceladosGraphs = [];
    this.lotesVendidosGraphs = [];
    this.contratosQuitadosGraphs = [];

    await this.service.ObterRelatorioPainelvendas(this.filter.empreendimentosIds.join(','), this.filter.empresasIds.join(','), this.filter.dataInicial, this.filter.dataFinal).then(
      (res) => {

    if (!res || !res.data) {
      this.isLoading = false;

      return; // Sai da função
    }

        this.modeloKPIVendasContrato = res.data;
        this.contratosCanceladosGraphs = this.extrairPrimeirosMesesCancelados(this.modeloKPIVendasContrato);
        this.lotesVendidosGraphs = this.extrairPrimeirosMesesVendidos(this.modeloKPIVendasContrato);
        this.contratosQuitadosGraphs = this.extrairPrimeirosMesesQuitados(this.modeloKPIVendasContrato);
        this.updateLotes();
      },
      (err) => {
        this.isLoading = false;
        TratarErroApi(err)
      }
    ) 
      this.isLoading = false;
  }

  extrairPrimeirosMesesCancelados(modeloKPIVendasContrato: KPIVendasContratoModel) {
    for (let i = modeloKPIVendasContrato.contratosCancelados.items.length - 3; i < modeloKPIVendasContrato.contratosCancelados.items.length; i++) {
      const itemCancelado = modeloKPIVendasContrato.contratosCancelados.items[i];
      if (itemCancelado) {
        this.contratosCanceladosGraphs.push({
          text: itemCancelado.mes.substring(0, 3).toUpperCase(),
          value: itemCancelado.quantidade
        });
      }
    }

    return this.contratosCanceladosGraphs;
  }

  extrairPrimeirosMesesVendidos(modeloKPIVendasContrato: KPIVendasContratoModel) {
    for (let i = modeloKPIVendasContrato.lotesVendidos.items.length - 3; i < modeloKPIVendasContrato.lotesVendidos.items.length; i++) {
      const itemVendido = modeloKPIVendasContrato.lotesVendidos.items[i];
      if (itemVendido) {
        this.lotesVendidosGraphs.push({
          text: itemVendido.mes.substring(0, 3).toUpperCase(),
          value: itemVendido.quantidade
        });
      }
    }

    return this.lotesVendidosGraphs;
  }

  extrairPrimeirosMesesQuitados(modeloKPIVendasContrato: KPIVendasContratoModel) {
    for (let i = modeloKPIVendasContrato.contratosQuitados.items.length - 3; i < modeloKPIVendasContrato.contratosQuitados.items.length; i++) {
      const itemQuitado = modeloKPIVendasContrato.contratosQuitados.items[i];
      if (itemQuitado) {
        this.contratosQuitadosGraphs.push({
            text: itemQuitado.mes.substring(0, 3).toUpperCase(),
            value: itemQuitado.quantidade
        });
      }
    }
    return this.contratosQuitadosGraphs;
  }

  loading: boolean = false;
  dialogCadastro: boolean = false;

  titulo: string = 'Vendas';
  subTitulo: string = 'Relatório de Vendas';

  options: any = {
    itemsPerPage: 15
  };

  mounted() {
    this.GetKPIVendasContrato();

    new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      res => this.empreendimentos = res.data.items
    );
 
    new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome, nomeFantasia', '').then(
      res => this.empresas = res.data.items
    );
  }   
}

class KPIVendasContratoModel{
  public lotesVendidos:any = {items:[]};
  public contratosQuitados:any = {items:[]};
  public contratosCancelados:any = {items:[]};
  public vendasCorretores:any = {items: []}
  public estadoLotes = new KPIEstadoLotes();
}

class KPIEstadoLotes{
  public item = {
  quantidadeDisponiveis:0,
  quantidadeIndisponiveis:0,
  quantidadeReservados:0,
  quantidadePenhorados:0,
  quantidadeInvadidos:0,
  quantidadeVendidos:0,
  }
  public totalLotes: number = 0;
}

